import { render, staticRenderFns } from "./newSearchResult.vue?vue&type=template&id=01a0cf2d&scoped=true&"
import script from "./newSearchResult.vue?vue&type=script&lang=js&"
export * from "./newSearchResult.vue?vue&type=script&lang=js&"
import style0 from "./newSearchResult.vue?vue&type=style&index=0&id=01a0cf2d&prod&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a0cf2d",
  null
  
)

export default component.exports