<template>
    <div>

        <div class="all_container">
            <div class="container">
                <!-- 首页切换 -->
                <div class="changeClassify">
                    <div class="title" @click="gohome">{{ $t('other.homePage') }}</div>
                    <div>-</div>
                    <div class="">{{ $t('home.searchResult') }}</div>
                </div>
                <div class="carousel">
                    <div class="carousel_box">
                        <div class="left">
                            <div class="city">
                                {{ $t('home.city') }}
                            </div>
                            <div class="price" style="margin-bottom: 15px;">
                                {{ $t('home.price') }}
                            </div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <div class="city_name" v-for="(item, index) in cityList" :key="index"
                                    @click="chooseCity(item, index)" :class="chooseIndex == index ? 'active' : ''">
                                    {{ item.name }}
                                    <div class="border" v-show="chooseIndex == index"></div>
                                </div>
                            </div>

                            <div class="center">
                                <el-checkbox-group v-model="checkList" v-for="(item, index) in checkCityList" :key="index"
                                    @change="changeCheck">
                                    <el-checkbox :label="item.name"></el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div class="bottom">
                                <div class="left">
                                    <input type="text" :placeholder="$t('home.minPrice')" v-model="minprice"
                                        @blur="getSearchInfo" />
                                    <div style="margin: 0 10px;">-</div>
                                    <input type="text" :placeholder="$t('home.maxPrice')" v-model="maxprice"
                                        @blur="getSearchInfo" />
                                </div>
                                <div class="right" @click="delCheck">
                                    {{ $t('home.clearChoose') }}
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <!-- 信息分类区 -->
                <div class="main_body">
                    <div class="info">
                        <!-- <div class="title">
                            <div>新楼盘</div>
                            <div class="border"></div>
                        </div> -->
                        <div class="loupan">
                            <div class="loupan_box" @click="goParticulars(item)" v-for="(item, index) in List" :key="index">
                                <div class="loupan_box_top">
                                    <img :src="item.thumb[0]" alt="" class="top_img">
                                </div>

                                <div class="loupan_box_bottom">
                                    <div class="top_box">
                                        <div class="top_box_title">
                                            {{ item.title }}
                                        </div>
                                    </div>

                                    <div class="center_box" style="display:block">
                                        <div style="margin-bottom: 5px;">
                                            ${{ item.price }}
                                        </div>
                                        <div class="bottom_box">
                                            <div class="bottom_box_left" style="white-space: nowrap; overflow:hidden; text-overflow:ellipsis; justify-content: left;">
                                                <img src="@/assets/new_home_icons/address_icon.png" alt="">
                                                <div class="name">
                                                    {{ item.address }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <!-- 分页 -->
                <div class="pagination">
                    <div class="total">
                        {{ $t('home.gong') }} {{ total }} {{ $t('home.tiao') }}
                    </div>
                    <div>
                        <el-pagination :background="true" layout="prev, pager, next" :total="total" @prev-click="prev"
                            @next-click="next" @current-change="handleCurrentChange" :page-size="pageSize"
                            :current-page="pageNo">
                        </el-pagination>
                    </div>
                </div>





            </div>
        </div>



    </div>
</template>

<script>


export default {

    data() {
        return {
            classifyStatus: '',
            recommendList: [], //热门推荐列表
            likeList: [], //喜欢列表

            currentPage: 1, //当前页数


            minprice: '', //最低价格
            maxprice: '',//最高价格

            List: [],

            // 城市部分的数据
            cityList: [], // 城市列表
            checkCityList: [], // 二级城市列表
            checkList: [], // 多选框选中的城市
            city_id: '', //城市一级id
            city_two_id: [],//城市二级id列表
            chooseIndex: '',


            lang: '',
            vip: '',
            loadingValue: false,
            tap: false,


            // 父组件传来的数据
			search_name:'',


            // 分页数据
            pageNo: 1,// 页码
            pageSize: 24,//每页多少条
            total: 0
        }
    },
    created() {

        this.search_name = this.$route.query.search_name
       

        console.log('打开')
        console.log('关闭')
        this.lang = localStorage.getItem('lang');

        this.getAds(1, 3);
        this.getAds(2, 4);
        this.getAds(3, 5);
        this.getAds(4, 6);
        this.getAds(5, 7);
        this.getAds(6, 8);
        this.getAds(7, 9);


        this.getCountry()
        this.getSearchInfo()



        // 实现监听localstorage中某个键对应的值的变化
        //根据自己需要来监听对应的key
        window.addEventListener("setItemEvent", (e) => {
            //e.key : 是值发生变化的key
            //例如 e.key==="token";
            //e.newValue : 是可以对应的新值
            if (e.key === "lang") {
                this.lang = e.newValue;
            }
        })

        this.getUserInfo();
    },

    mounted() {

    },
   
    watch: {
        '$route'(val, from) {
            // 拿到目标参数 val.query.id 去请求接口
            console.log(val);
            this.search_name = val.query.search_name
            console.log(this.search_name,'this.search_name');
            this.getSearchInfo()
        },
    },
    methods: {
        prev() {
            console.log('上一页')
            this.pageNo--
            this.getSearchInfo()
        },
        next() {
            console.log('下一页')
            this.pageNo++
            this.getSearchInfo()
        },

        handleCurrentChange(val) {
            this.pageNo = val
            this.getSearchInfo()
            console.log(`当前页: ${val}`);
        },

        gohome() {
            this.classifyStatus = 1
            this.$emit('chaangeClassifyStatus', this.classifyStatus)
        },

        goParticulars(item) {
            if (item.url == 1) {
                this.$router.push(
                    {
                        path: '/particulars',
                        query: {
                            id: item.id,
                        }
                    }
                )
            } else {
                this.$router.push(
                    {
                        path: '/details',
                        query: {
                            id: item.id,
                        }
                    }
                )
            }
        },
        tapCity(item, index) {
            console.log(item, index);
            this.tap = index
        },

        // 轮播图切换事件
        bannerArrChange(item) {
            console.log(item);
            let ads_type = item.ads_type;
            let bind_information_id = item.bind_information_id;
            let href = item.href;
            if (ads_type == 1) {
                if (href) {
                    window.open(href)
                }
            } else {
                this.$router.push({
                    path: '/details',
                    query: {
                        id: bind_information_id,
                    }
                });
            }
        },

        // 获取本页面列表详情
        getSearchInfo() {
            this.$http.getSearchInfo({
                search_name: this.search_name,
                order: 'desc',
                min_money: this.minprice,
                max_money: this.maxprice,
                province_id: this.city_id,
                addr_id: this.city_two_id,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then(res => {
                if (res.code == 1) {
                    console.log(res, '获取本页面详情');
                    this.List = res.data.data
                    this.total = res.data.total

                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 获取用户信息
        getUserInfo() {
            this.$http.userInfo().then(res => {
                if (res.code == 1) {
                    this.vip = res.data.vip;
                } else if (res.code == -201) {
					
				} else {
                    this.$message.info(res.msg);
                }
            })
        },
        // 获取轮播图
        getAds(type, typeid) {
            console.log(type, '快捷键了')
            this.$http.getAds({
                typeid,
            }).then(res => {
                if (res.code == 1) {
                    switch (type) {
                        case 1:
                            this.bannerArr1 = res.data;
                            break;
                        case 2:
                            this.bannerArr2 = res.data;
                            break;
                        case 3:
                            this.bannerArr3 = res.data;
                            break;
                        case 4:
                            this.bannerArr4 = res.data;
                            break;
                        case 5:
                            this.bannerArr5 = res.data;
                            break;
                        case 6:
                            this.bannerArr6 = res.data;
                            break;
                        case 7:
                            this.bannerArr7 = res.data;
                            break;
                    }
                } else {
                    this.$message.info(res.msg);
                }
            })
        },
        // 	去热门推荐
        toHotRecommand() {
            // let types = 1;
            this.$router.push({
                path: '/hotRecommand',
                // query:{
                //     pages:types,
                // }
            });
            // window.open('/hotRecommand')
            // let routeUrl=this.$router.resolve({
            // 	path: '/home/hotRecommand'
            // })
            // window.open(routeUrl.href,'_blank')
        },
        // 获取热门推荐
        getRecommend() {
            this.$http.getRecommend({
                pageNo: 1,
                pageSize: 24,
                is_index: 1,
                type: 1,
            }).then(res => {
                if (res.code == 1) {
                    this.recommendList = res.data.data;
                    this.$forceUpdate();
                } else {
                    this.$message.error(res.data.msg);
                }
                // console.log(res);
            }).catch((v) => {
                console.log(v);
            });
        },
        // 获取所有喜欢的
        getAllLike() {
            let data = {
                pageNo: this.currentPage,
                pageSize: this.pageSize,
                is_index: 1,
                type: 2,
            };
            this.loadingValue = true;
            this.$http.getRecommend(data).then(res => {
                this.loadingValue = false;
                window.scrollTo(0, 0)
                console.log(res);
                if (res.code == 1) {
                    this.total = res.data.total;
                    this.likeList = res.data.data;
                } else {
                    this.$message.info(res.msg);
                }
            }).catch(() => {
                this.loadingValue = false;
            })
        },

        currentChangeClick(currentPage) {
            this.currentPage = currentPage;
            this.getAllLike();

        },
        sizeChangeClick(pageSize) {
            this.pageSize = pageSize;
            this.getAllLike();
        },


        // 获取城市列表
        getCountry() {
            this.$http.getCountry().then(res => {
                if (res.code == 1) {
                    this.cityList = res.data
                    this.checkCityList = res.data[0].children
                    // log(this.cityList, '城市列表')
                } else {
                    this.$messgae.info(res.msg)
                }
            })
        },


        // 选择城市
        chooseCity(item, index) {
            this.city_two_id = []
            console.log(item, '二级城市');
            this.chooseIndex = index
            this.checkCityList = item.children
            this.city_id = item.id //一级id
            this.checkList = []
            this.getSearchInfo()

        },

        changeCheck(e) {
            console.log(e);
            this.city_two_id = e //二级id列表
            this.getSearchInfo()


        },


        // 清空选项
        delCheck() {
			this.search_name = ''
            this.chooseIndex = ''
            this.pageNo = 1,
            this.minprice = ''
            this.maxprice = ''
            this.checkList = []
            this.city_id = ''
            this.city_two_id = []
            this.getSearchInfo()
        }
    },

}
</script>

<style lang="less" scoped="scoped">
.active {
    color: #F39800;
}

.no_active {
    color: #333333;
}

.top_fix {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 500;
}

.big_img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.all_container {
    background: #F6F7F9;
    // padding-top: 150px;

}

.container {
    width: 100%;
    // height: 446px;
    margin: 0 auto;
    padding-bottom: 64px;
}

.changeClassify {
    display: flex;
    // justify-content: space-between;
    // width: 84px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 32px 0 0 32px;

    .title {
        color: #F39800;
    }
}

.carousel {
    padding: 0 32px;
    margin-top: 36px;
    display: flex;

    .carousel_box {
        display: flex;
        justify-content: left;
        box-sizing: border-box;
        background-color: #fff;
        // height: 258px;
        width: 100%;
        padding: 32px;

        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
        }

        .right {
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 40px;

            .top {
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                flex-wrap: nowrap;

                .city_name {
                    // margin-right: 40px;

                    .border {
                        margin: 6px auto 0;
                        width: 40px;
                        height: 3px;
                        background: rgba(243, 152, 0);
                        border-radius: 2px;
                    }
                }
            }

            .center {
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                flex-wrap: nowrap;
                margin: 24px 0px 33px 0px;
            }

            .bottom {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;

                .left {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: left;
                    box-sizing: border-box;

                    input {
                        width: 210px;
                        height: 40px;
                        border: 1px solid #DEDEDE;
                        opacity: 1;
                        border-radius: 4px;
                        text-align: center;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                    }
                }


                .right {
                    width: 180px;
                    text-align: right;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #4177DD;
                    white-space: nowrap;
                }


            }
        }



    }

}

.main_body {

    // 信息分类
    .info {
        // padding-top: 88px;
        background: #F6F7F9;
        padding: 0 36px;


        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;

            .border {
                width: 40px;
                height: 4px;
                background: rgba(243, 152, 0);
                border-radius: 2px;
                margin-top: 8px;
            }
        }



        .classify {
            position: relative;
            background-color: #fff;
            height: 460px;
            width: 100%;
            box-sizing: border-box;

            .top {
                margin-top: 32px;
                padding-top: 71px;
                display: grid;
                justify-content: center;
                grid-template-columns: 16% 16% 16% 16% 16% 16%;

                .top_box {
                    position: relative;
                    margin-bottom: 48px;
                    margin-right: 10px;

                    .img {
                        position: absolute;
                        top: -30%;
                        left: 50%;
                        transform: translatex(-60%);
                        width: 70px;
                        height: 70px;

                        // background-color: pink;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .top_box_border {
                        // width: 280px;
                        height: 105px;
                        border-radius: 5px;
                        // background-color: pink;
                        border: 1px solid #FF8100;
                        border-bottom: 6px solid #FF8100;

                        .title {
                            margin-top: 50px;
                            font-size: 22px;
                            text-align: center;
                        }
                    }
                }

            }

            .bottom {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 48px;
                background-image: url('../../../assets/new_home_icons/bottom.png');
                background-repeat: no-repeat;
                background-size: cover;

            }


        }

        .loupan {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .loupan_box {
                width: 16%;
                height: 400px;
                background: #fff;
                margin-top: 24px;

                .loupan_box_top {
                    width: 100%;
                    height: 260px;

                    .top_img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .loupan_box_bottom {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    padding: 0 16px;

                    .top_box {
                        display: flex;
                        justify-content: space-between;
                        margin: 16px 0 12px;

                        .top_box_title {
                            // height: 42px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        .top_box_img {
                            width: 80px;
                            height: 48px;
                        }
                    }

                    .center_box {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #F39800;
                        text-align: left;
                        display: flex;
                        justify-content: space-between;
                    }

                    .bottom_box {
                        display: flex;
                        justify-content: left;

                        .bottom_box_left {
                            display: flex;
                            justify-content: end;
                            align-items: center;

                            img {
                                width: 14px;
                                height: 14px;
                            }

                            .name {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #888888;
                            }
                        }
                    }
                }
            }

            .loupan_box:nth-child(6n) {
                margin-right: 0px !important;
            }
        }





    }

}

.pagination {
    display: flex;
    padding: 0 32px;
    margin-top: 46px !important;
    justify-content: space-between !important;

    .total {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0);
    }

    :deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
        background-color: #F39800 !important; //修改默认的背景色
    }

}



.hot_recommand {
    box-sizing: border-box;
    display: flex;
    margin-top: 16px;

    .hot_recommand_left {
        width: 232px;

        .two {
            margin-top: 16px;
        }
    }

    .hot_recommand_right {
        width: 968px;
        padding-left: 10px;

        .hot_img {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;

            img {
                width: 205px;
                height: 52px;
            }
        }

        .all_house {
            display: flex;
            flex-wrap: wrap;
        }

        .check_more {
            margin-top: 24px;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 120px;
                height: 36px;
            }
        }
    }
}



.vip {
    display: flex;
    margin-top: 40px;

    .vip_left {
        width: 232px;
    }

    .vip_right {
        width: 968px;


        .vip_img {

            img {
                width: 928px;
                height: 112px;
            }
        }

        .vip_carousel {
            margin-top: 32px;
            display: flex;
            justify-content: space-between;

            .vip_carousel_left {
                width: 474px;
            }

            .vip_carousel_right {
                width: 474px;
            }
        }

        .vip_big_carousel {
            margin-top: 10px;
            width: 958px;
        }
    }
}

.like {
    .like_img {
        margin: 40px 0 20px;

        img {
            width: 316px;
            height: 44px;
        }
    }

    .all_house {
        display: flex;
        flex-wrap: wrap;
    }
}


.carousel_left {
    width: 232px;

    /deep/ .el-carousel__arrow {
        top: 72%;
    }

    /deep/ .el-carousel__arrow--left,
    /deep/ .el-carousel__arrow--right {
        width: 24px;
        height: 24px;
    }
}

.carousel_left2 {
    width: 232px;

    /deep/ .el-carousel__arrow {}

    /deep/ .el-carousel__arrow--left,
    /deep/ .el-carousel__arrow--right {
        width: 24px;
        height: 24px;
    }
}


/deep/.el-carousel__indicators--horizontal {

    .el-carousel__indicator--horizontal button {
        width: 8px;
        height: 8px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 0.5;
    }

    .el-carousel__indicator--horizontal.is-active button {
        width: 24px;
        height: 8px;
        background: #ffffff;
        opacity: 0.5;
        border-radius: 10px;
    }
}

.pagination {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

// 新增的样式
.loading {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0rpx;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingImg {
    width: 100rpx;
    height: 100rpx;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 11;
}

/deep/.el-checkbox__inner {
    border-color: #D1D1D0;
}

// /deep/.is-checked{
//     background-color: #F39800 !important;
//     border-color: #F39800 !important;
// }
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #F39800;
    border-color: #F39800;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #F39800;
}
</style>
